import React, { useState } from "react";

function Securite({ onAccess }) {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");

  const correctCode = process.env.REACT_APP_CORRECT_CODE; // Changez ce code selon vos besoins

  const handleSubmit = (e) => {
    e.preventDefault();
    if (code.toString() === correctCode.toString()) {
      onAccess(true);
    } else {
      setError("Code incorrect");
      setCode("");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">Accès sécurisé</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">
              Entrez le code d'accès :
            </label>
            <input
              type="password"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
          >
            Accéder
          </button>
        </form>
      </div>
    </div>
  );
}

export default Securite;
