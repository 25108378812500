import { useState, useEffect } from "react";
import { ref, get, set } from "firebase/database";
import { db } from "./firebase";
import "./App.css";
import Securite from "./components/Securite";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [usersLink, setUsersLink] = useState([]);
  const [points, setPoints] = useState({});
  const [updateStatus, setUpdateStatus] = useState({});
  const [activeTab, setActiveTab] = useState("addPoints");
  const [scores, setScores] = useState([]);

  useEffect(() => {
    const fetchUsersLink = async () => {
      try {
        const dbRef = ref(db, "users_links");
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const usersData = await Promise.all(
            Object.entries(data).map(async ([userId, links]) => {
              const userRef = ref(db, `users/${userId}/first_name`);
              const userSnapshot = await get(userRef);
              const firstName = userSnapshot.exists()
                ? userSnapshot.val()
                : "Inconnu";
              return {
                userId,
                firstName,
                links: Object.entries(links).map(([linkId, linkData]) => ({
                  linkId,
                  url: typeof linkData === "string" ? linkData : linkData.url,
                  status: typeof linkData === "string" ? null : linkData.status,
                })),
              };
            })
          );
          setUsersLink(usersData);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };

    const fetchScores = async () => {
      try {
        const dbRef = ref(db, "users");
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const scoresData = Object.entries(data).map(([userId, userData]) => ({
            userId,
            firstName: userData.first_name || "Inconnu",
            username: userData.username || "Non renseigné",
            score: userData.score || 0,
            polygonWallet: userData.polygon_wallet || null,
          }));
          scoresData.sort((a, b) => b.score - a.score);
          setScores(scoresData);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des scores :", error);
      }
    };

    if (isAuthenticated) {
      fetchUsersLink();
      fetchScores();
    }
  }, [isAuthenticated]);

  const updatePoints = async (userId, linkId) => {
    try {
      const userPoints = points[userId]?.[linkId];
      if (!userPoints) return;

      const userRef = ref(db, `users/${userId}/score`);
      const snapshot = await get(userRef);
      let currentScore = 0;
      if (snapshot.exists()) {
        currentScore = snapshot.val();
      }

      const newScore = currentScore + parseInt(userPoints);
      await set(userRef, newScore);

      const userLinksRef = ref(db, `users_links/${userId}/${linkId}`);
      const linkSnapshot = await get(userLinksRef);
      if (linkSnapshot.exists()) {
        const linkData = linkSnapshot.val();
        await set(userLinksRef, {
          url: typeof linkData === "string" ? linkData : linkData.url,
          status: `${userPoints} points`,
        });
      }

      setUsersLink((prevUsers) =>
        prevUsers.map((user) => {
          if (user.userId === userId) {
            return {
              ...user,
              links: user.links.map((link) => {
                if (link.linkId === linkId) {
                  return {
                    ...link,
                    status: `${userPoints} points`,
                  };
                }
                return link;
              }),
            };
          }
          return user;
        })
      );

      setPoints((prev) => ({
        ...prev,
        [userId]: {
          ...prev[userId],
          [linkId]: "",
        },
      }));
      setUpdateStatus((prev) => ({
        ...prev,
        [userId]: "success",
      }));
    } catch (error) {
      console.error("Erreur lors de la mise à jour des points:", error);
      setUpdateStatus((prev) => ({
        ...prev,
        [userId]: "error",
      }));
    }
  };

  if (!isAuthenticated) {
    return <Securite onAccess={setIsAuthenticated} />;
  }

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">Gestion des Points</h1>
      <div className="mb-4">
        <button
          onClick={() => setActiveTab("addPoints")}
          className={`px-4 py-2 mr-2 ${
            activeTab === "addPoints" ? "bg-blue-500 text-white" : "bg-gray-200"
          }`}
        >
          Ajouter des Points
        </button>
        <button
          onClick={() => setActiveTab("viewScores")}
          className={`px-4 py-2 ${
            activeTab === "viewScores"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          }`}
        >
          Voir les Scores
        </button>
      </div>

      {activeTab === "addPoints" && (
        <div className="grid gap-8">
          {usersLink.map((user) => {
            const completedLinks = user.links.filter((link) => link.status);
            const pendingLinks = user.links.filter((link) => !link.status);

            return (
              <div key={user.userId} className="space-y-6">
                {pendingLinks.length > 0 && (
                  <div className="p-4 border rounded-lg shadow-lg bg-white">
                    <h2 className="text-xl font-semibold mb-4 text-gray-800">
                      En attente - {user.firstName}
                    </h2>
                    <div className="overflow-x-auto">
                      <table className="min-w-full table-auto">
                        <thead className="bg-gray-100">
                          <tr>
                            <th className="px-4 py-2 text-left text-gray-600">
                              Nom
                            </th>
                            <th className="px-4 py-2 text-left text-gray-600">
                              URL
                            </th>
                            <th className="px-4 py-2 text-left text-gray-600">
                              Actions
                            </th>
                            <th className="px-4 py-2 text-left text-gray-600">
                              Points
                            </th>
                            <th className="px-4 py-2 text-left text-gray-600">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {pendingLinks.map((link) => (
                            <tr
                              key={link.linkId}
                              className="border-b hover:bg-gray-50"
                            >
                              <td className="px-4 py-2 text-gray-800">
                                {user.firstName}
                              </td>
                              <td className="px-4 py-2 text-gray-800">
                                <span className="truncate block max-w-md">
                                  {link.url}
                                </span>
                              </td>
                              <td className="px-4 py-2">
                                <a
                                  href={link.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-500 hover:text-blue-700 underline"
                                >
                                  Ouvrir le lien
                                </a>
                              </td>
                              <td className="px-4 py-2">
                                <div className="flex items-center space-x-2">
                                  <input
                                    type="number"
                                    value={
                                      points[user.userId]?.[link.linkId] || ""
                                    }
                                    onChange={(e) =>
                                      setPoints((prev) => ({
                                        ...prev,
                                        [user.userId]: {
                                          ...prev[user.userId],
                                          [link.linkId]: e.target.value,
                                        },
                                      }))
                                    }
                                    className="w-20 px-2 py-1 border rounded"
                                    placeholder="Points"
                                  />
                                  <button
                                    onClick={() =>
                                      updatePoints(user.userId, link.linkId)
                                    }
                                    className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600"
                                  >
                                    +
                                  </button>
                                </div>
                              </td>
                              <td className="px-4 py-2">
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                  En attente
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {completedLinks.length > 0 && (
                  <div className="p-4 border rounded-lg shadow-lg bg-white">
                    <h2 className="text-xl font-semibold mb-4 text-green-800">
                      Points Ajoutés - {user.firstName}
                    </h2>
                    <div className="overflow-x-auto">
                      <table className="min-w-full table-auto">
                        <thead className="bg-green-50">
                          <tr>
                            <th className="px-4 py-2 text-left text-gray-600">
                              Nom
                            </th>
                            <th className="px-4 py-2 text-left text-gray-600">
                              URL
                            </th>
                            <th className="px-4 py-2 text-left text-gray-600">
                              Actions
                            </th>
                            <th className="px-4 py-2 text-left text-gray-600">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {completedLinks.map((link) => (
                            <tr
                              key={link.linkId}
                              className="border-b hover:bg-green-50"
                            >
                              <td className="px-4 py-2 text-gray-800">
                                {user.firstName}
                              </td>
                              <td className="px-4 py-2 text-gray-800">
                                <span className="truncate block max-w-md">
                                  {link.url}
                                </span>
                              </td>
                              <td className="px-4 py-2">
                                <a
                                  href={link.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-500 hover:text-blue-700 underline"
                                >
                                  Ouvrir le lien
                                </a>
                              </td>
                              <td className="px-4 py-2">
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                  ✓ {link.status}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}

      {activeTab === "viewScores" && (
        <div className="p-4 border rounded-lg shadow-lg bg-white">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">
            Scores Totaux
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead className="bg-gray-100">
                <tr>
                  <th className="px-4 py-2 text-left text-gray-600">
                    Position
                  </th>
                  <th className="px-4 py-2 text-left text-gray-600">
                    ID Utilisateur
                  </th>
                  <th className="px-4 py-2 text-left text-gray-600">Prénom</th>
                  <th className="px-4 py-2 text-left text-gray-600">
                    Username
                  </th>
                  <th className="px-4 py-2 text-left text-gray-600">Score</th>
                  <th className="px-4 py-2 text-left text-gray-600">
                    Adresse Polygon
                  </th>
                  <th className="px-4 py-2 text-left text-gray-600">Actions</th>
                </tr>
              </thead>
              <tbody>
                {scores.map((user, index) => (
                  <tr key={user.userId} className="border-b hover:bg-gray-50">
                    <td className="px-4 py-2 text-gray-800">
                      <span
                        className={`inline-flex items-center justify-center w-6 h-6 rounded-full 
                        ${
                          index === 0
                            ? "bg-yellow-100 text-yellow-800"
                            : index === 1
                            ? "bg-gray-100 text-gray-800"
                            : index === 2
                            ? "bg-orange-100 text-orange-800"
                            : "bg-white"
                        }`}
                      >
                        {index + 1}
                      </span>
                    </td>
                    <td className="px-4 py-2">
                      <span className="text-sm font-mono bg-gray-100 px-2 py-1 rounded">
                        {user.userId}
                      </span>
                    </td>
                    <td className="px-4 py-2 text-gray-800">
                      {user.firstName}
                    </td>
                    <td className="px-4 py-2">
                      {user.username !== "Non renseigné" ? (
                        <span className="text-blue-600 font-medium">
                          @{user.username}
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          Non renseigné
                        </span>
                      )}
                    </td>
                    <td className="px-4 py-2 font-semibold text-gray-800">
                      {user.score}
                    </td>
                    <td className="px-4 py-2 text-gray-800">
                      {user.polygonWallet ? (
                        <span className="font-mono text-sm">
                          {user.polygonWallet.slice(0, 6)}...
                          {user.polygonWallet.slice(-4)}
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          Non renseigné
                        </span>
                      )}
                    </td>
                    <td className="px-4 py-2">
                      {user.polygonWallet ? (
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(user.polygonWallet);
                            alert("Adresse copiée !");
                          }}
                          className="text-blue-500 hover:text-blue-700 underline text-sm"
                        >
                          Copier l'adresse
                        </button>
                      ) : (
                        <span className="text-gray-400 text-sm">-</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
