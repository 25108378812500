import { initializeApp } from "firebase/app";
import { getDatabase, ref, get } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBvwYSZ67dFoCieSuYVI_19TMvOw6DNj8w",
  authDomain: "degen-f7aaf.firebaseapp.com",
  databaseURL:
    "https://degen-f7aaf-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "degen-f7aaf",
  storageBucket: "degen-f7aaf.firebasestorage.app",
  messagingSenderId: "783525413443",
  appId: "1:783525413443:web:ea02dc43344e83e30c4af6",
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
